label.file-input-label {
  font-size: 16px;
  color: var(--text-color-primary) !important;
  font-weight: bold;
  margin-bottom: 6px;
  letter-spacing: 0;
}

.file-input-wrapper {
  width: 100%;
  height: 60px;
  border: solid 1px var(--text-color-primary);
  padding: 4px 12px;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-primary);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.6rem;
  cursor: pointer;

  cancel-icon {
    transform: rotate(45deg);
  }
}

.image-preview-wrapper {
  position: relative;
  width: auto;
  height: auto;

  close-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}
