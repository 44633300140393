.dark-tone-text {
  color: var(--dark-tone) !important;
}

.text-gray-3 {
  color: var(--text-color-medium) !important;
}

.text-gray {
  color: var(--text-color-light) !important;
}

.primary-text {
  color: var(--primary-color);
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fw-500 {
  font-weight: 500 !important;
}
