.business-review-wrapper {
  padding: 0 1rem;
  min-height: 100vh;

  h1 {
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    font-size: 21px;
  }

  h2 {
    font-weight: 700;
    line-height: normal;
    font-size: 1.1rem;
  }

  &.p-0 {
    padding: 0;

    .h-100 {
      padding: 0px 1rem;
      min-height: calc(100vh - 20vh);
    }

    .custom-scroll {
      max-height: calc(67vh - 7rem);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .restaurant-cover {
    position: relative;

    .restaurant-image {
      width: 100%;
      height: 20vh;
      object-fit: cover;
    }

    menufy-web-save-button {
      position: absolute;
      margin: 0 1rem;
      z-index: 2;
      top: 1.5rem;
      left: 0;
    }
  }

  .stepper-header {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .brand-logo {
      width: 9rem;
      height: 9rem;
      object-fit: contain;
    }
  }

  .stepper-actions {
    width: 100%;
    position: absolute;
    left: 0;
    padding: 0 1rem;
    bottom: 2rem;
  }

  .stepper-footer {
    width: 100%;
    position: absolute;
    left: 0;
    padding: 0 1rem;
    bottom: 4rem;

    .stepper-count {
      font-size: 14px;
      color: var(--text-color-primary);
      margin-bottom: 8px;
    }

    .stepper-description {
      font-size: 13px;
      color: var(--text-color-primary);
    }

    .stepper-title {
      font-size: 16px;
      font-weight: bold;
      color: var(--text-color-primary);
    }
  }
}

.dish-preview {
  padding: 10px 0px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  display: flex;

  .dish-content {
    width: calc(100% - 134px);
  }

  h3 {
    font-size: 0.9rem;
    letter-spacing: 0;
    line-height: normal;
    font-weight: 700;
  }

  p {
    line-height: normal;
    letter-spacing: 0;
  }

  h4 {
    font-weight: bold;
    margin-bottom: 0px;
  }

  .dish-image,
  .dish-image-holder {
    margin-left: 1em;
    object-fit: cover;
    border-radius: 12px;
    min-width: 120px;
    width: 120px;
    height: 120px;
  }
}

.labels-image {
  min-width: 32px;
  width: 32px;
  height: 32px;

  &.additional {
    min-width: 20px;
    width: auto;
    height: 22px;
    object-fit: contain;
    margin-left: 0px;
    margin-top: -5px;
  }
}

.dish-image {
  width: 112px;
  height: 112px;
  border-radius: 12px;
  object-fit: cover;
}

.dish-image-holder {
  width: 112px;
  height: 112px;
  border-radius: 12px;
  background: #cecece;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dish-list-wrapper {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;

  h4 {
    font-weight: bold;
    margin-bottom: 0px;
  }

  .dish-image,
  .dish-image-holder {
    width: 60px !important;
    height: 60px !important;
  }
}
