.primary-btn,
.secondary-btn {
  color: #fff !important;
  letter-spacing: 0 !important;
  border-radius: 0.7rem !important;
  font-weight: 600;
}

.secondary-btn {
  background: var(--secondary-color) !important;

  &.disabled,
  &:disabled {
    @extend %disabled-button-gray;
  }
}

.primary-btn {
  background: var(--primary-color) !important;

  &.disabled,
  &:disabled {
    @extend %disabled-button-gray;
  }
}

.dark-btn {
  border-radius: 0.5rem !important;
  letter-spacing: 0 !important;
  background-color: var(--text-color-primary) !important;
  color: #fff !important;
  font-weight: 600;

  &.disabled,
  &:disabled {
    @extend %disabled-button-gray;
  }
}

.gray-btn {
  border-radius: 0.5rem !important;
  letter-spacing: 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  font-weight: 600;

  &.disabled,
  &:disabled {
    @extend %disabled-button-gray;
  }
}

.add-btn {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled,
  &:disabled {
    @extend %disabled-button-gray;
  }
}

.outline-btn {
  border-radius: 2rem !important;
  letter-spacing: 0 !important;
  font-size: 1rem !important;
  background-color: #fff !important;
  color: var(--text-color-primary) !important;
  border: solid 1px var(--text-color-light) !important;
  font-weight: 600;
}

%disabled-button-gray {
  background: var(--text-color-primary) !important;
  opacity: 0.3 !important;
  pointer-events: none !important;
}
