.business-info-wrapper {
  padding: 0 1rem;
  min-height: calc(100vh - 13rem);
  max-height: calc(100vh - 13rem);
  overflow-y: auto;
  overflow-x: hidden;

  &.no-actions {
    min-height: calc(100vh - 12rem);
    max-height: calc(100vh - 12rem);
  }

  .wrapper {
    width: 100%;
    margin-top: 1.2rem;
    border-radius: 6px;
    height: fit-content;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    display: block;
    padding: 1rem;

    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
  }
}

.business-header {
  padding: 1rem;
  background-color: #fff;
  position: relative;
  max-height: 8rem;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

  .col-12 {
    position: relative;
  }

  h2 {
    margin: 0;
    padding: 0;
  }

  .arrow-back {
    position: absolute;
    left: 0;
  }
}

display-value {
  h4 {
    margin-bottom: 4px !important;
    word-break: break-word;
  }

  a {
    margin: 0px !important;
    padding: 0px !important;
    color: var(--text-color-medium);
    text-decoration: underline;

    &.work-break {
      word-wrap: break-word;
    }
  }

  p {
    margin: 0px !important;
    padding: 0px !important;
    color: var(--text-color-medium);

    &.work-break {
      word-wrap: break-word;
    }
  }
}

.menu-list-wrapper {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  mat-icon {
    cursor: pointer !important;
    font-size: 20px;
  }
}
