.form-input-wrapper {
  label {
    font-size: 15px;
    color: var(--text-color-primary) !important;
    font-weight: bold;
    margin-bottom: 6px;
    letter-spacing: 0;
  }

  .form-input-group {
    position: relative;

    select.form-input {
      width: 100%;
      height: 48px;
      border: solid 1px var(--text-color-light);
      padding: 4px 8px;
      border-radius: 0.5rem;
      font-size: 14px;
      color: var(--text-color-primary);
      outline: none;

      &::placeholder {
        font-size: 12px;
        color: var(--text-color-light) !important;
        font-style: italic;
      }

      &:hover,
      &:focus {
        border-color: var(--text-color-primary);
      }
    }
  }
}
