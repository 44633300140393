.add-business-steps-wrapper {
  padding: 0 1rem;
  min-height: 100vh;

  &.p-0 {
    padding: 0;

    .h-100 {
      min-height: calc(86vh);
    }

    .custom-scroll {
      max-height: calc(86vh - 6rem);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .restaurant-cover {
    position: relative;

    .restaurant-image {
      width: 100%;
      height: 14vh;
      object-fit: cover;
    }

    menufy-web-save-button {
      position: absolute;
      margin: 0 1rem;
      z-index: 2;
      top: 4rem;
      left: 0;
    }
  }

  .stepper-header {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .brand-logo {
      width: 9rem;
      height: 9rem;
      object-fit: contain;
    }
  }

  .stepper-actions {
    width: 100%;
    position: absolute;
    left: 0;
    padding: 0 0.7rem;
    bottom: 3rem;
  }

  .stepper-footer {
    width: 100%;
    position: absolute;
    left: 0;
    padding: 0 1rem;
    bottom: 4rem;

    .stepper-count {
      font-size: 14px;
      color: var(--text-color-primary);
      margin-bottom: 8px;
    }

    .stepper-description {
      font-size: 13px;
      color: var(--text-color-primary);
    }

    .stepper-title {
      font-size: 16px;
      font-weight: bold;
      color: var(--text-color-primary);
    }
  }
}
