.mvh-100 {
  min-height: 100vh;
}

.mvw-100 {
  min-width: 100vw;
}

.mvh-80 {
  min-height: 82vh;
}

.position-relative {
  position: relative;
}

.drag-handle {
  color: rgba(0, 0, 0, 0.4) !important;
  cursor: move !important;
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  top: 0;
  left: 3px;
}

.mat-bottom-sheet-container {
  border-radius: 12px 12px 0px 0px !important;
  width: 100vw !important;
  max-width: 900px !important;
  max-height: 96vh !important;

  .popup-close-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
  }
}
