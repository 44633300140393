/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --primary-color: #00b992;
  --secondary-color: #82ecd5;
  --accent-color: #c2fef1;
  --dark-tone: #009576;
  --primary-color-hex: 0, 185, 146, 0.5;
  --secondary-color-hex: 130, 236, 213, 0.5;
  --accent-color-hex: 194, 254, 241, 0.5;
  --dark-tone-hex: 0, 149, 118, 0.5;
  --text-color-primary: #1d1c1a;
  --text-color-primary-dark: #100f0e;
  --text-color-secondary: #393836;
  --text-color-medium: #625e5d;
  --text-color-light: #91908c;
  --primary-font: "Open Sans", sans-serif;
}

* {
  font-family: var(--primary-font) !important;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: var(--primary-font) !important;
}

.no-scroll {
  overflow: hidden;
}

mat-icon {
  font-family: "Material Icons" !important;
}

.translate-popup {
  .mat-bottom-sheet-container {
    background: none;
    box-shadow: none;
    padding: 0;
    width: 100%;
    max-height: 95vh;
  }
}
