.mdc-checkbox__background {
  width: 20px !important;
  height: 20px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: var(--text-color-primary) !important;
  border: var(--text-color-primary) !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: #fff !important;
  top: 2px !important;
  left: 2.15px !important;
  width: 80% !important;
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  display: none !important;
}
