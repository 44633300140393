.screen-loading-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-title {
    font-size: 14px;
    color: var(--text-color-primary);
    letter-spacing: normal;
    position: absolute;
    top: 1rem;
    font-weight: bold;
  }

  .loading-img {
    width: 10rem;
    height: 10rem;
    object-fit: contain;
  }
}

.spinner-loading {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid var(--primary-color); /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
