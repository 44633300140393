a.dark-link {
  color: var(--text-color-primary);
  text-decoration: underline;
  transition: 0.2s ease;

  &:hover {
    color: var(--text-color-primary-dark);
  }
}

a.forgot-password {
  font-size: 1rem;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: 0;
  line-height: normal;
  color: var(--text-color-primary);
  transition: 0.2s;

  &:hover {
    color: var(--text-color-primary-dark);
  }
}
