.form-input-wrapper {
  label {
    font-size: 15px;
    color: var(--text-color-primary) !important;
    font-weight: bold;
    margin-bottom: 6px;
    letter-spacing: 0;
  }

  .input-group-text.dollar-sign {
    background-color: #fff;
    border: none;
    width: fit-content;
    color: var(--primary-color) !important;
  }

  .form-control.border-all {
    border-radius: 0.5rem !important;
  }

  .form-input-group {
    position: relative;

    input.form-input {
      width: 100%;
      height: 48px;
      border: solid 1px var(--text-color-light);
      padding: 4px 8px;
      border-radius: 0.5rem;
      font-size: 14px;
      color: var(--text-color-primary);
      outline: none;

      &::placeholder {
        font-size: 12px;
        color: var(--text-color-light) !important;
        font-style: italic;
      }

      &:hover,
      &:focus {
        border-color: var(--text-color-primary);
      }
    }

    textarea.form-input {
      width: 100%;
      height: 96px;
      border: solid 1px var(--text-color-light);
      padding: 4px 8px;
      border-radius: 0.5rem;
      font-size: 14px;
      color: var(--text-color-primary);
      outline: none;

      &::placeholder {
        font-size: 12px;
        color: var(--text-color-light) !important;
        font-style: italic;
      }

      &:hover,
      &:focus {
        border-color: var(--text-color-primary);
      }
    }

    close-icon {
      z-index: 2;
      position: absolute;
      right: 6px;
      top: 2px;
    }

    .password-toggle {
      color: var(--text-color-primary);
      text-decoration: underline;
      font-size: 0.8rem;
      font-weight: bold;
      position: absolute;
      right: 12px;
      top: 14px;
    }
  }
}
